import {
  asosGlobalNamespace,
  siteChromeGlobalNamespace
} from "@src/template/constants";

let cookieData;

export const getInitialCookieData = () => {
  if (!cookieData) {
    cookieData =
      // eslint-disable-next-line no-underscore-dangle
      window[asosGlobalNamespace][siteChromeGlobalNamespace]._cookieData;
    // eslint-disable-next-line no-underscore-dangle
    delete window[asosGlobalNamespace][siteChromeGlobalNamespace]._cookieData;
  }

  return cookieData;
};
