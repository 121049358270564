import { timeToMilliseconds } from "@src/helpers/timeUtils";
import { removeValueFromLocalStorage } from "@src/helpers/webStorage";
import { isAndroid, isIos } from "@src/helpers/deviceDetection";
import { isWindowDefined } from "@src/helpers/url";

export const OPEN_IN_APP_DEFAULT_ACTION = 0;
export const OPEN_IN_APP_USER_ACTION = 1;
export const OPEN_IN_APP_UNEXPECTED_USER_ACTION = 2;

export const NATIVE_BANNER_STORAGE_KEY = "asos.siteChrome.nativeBanner";
export const NATIVE_BANNER_AD_PARAMS_STORAGE_KEY =
  "asos.siteChrome.nativeBanner.adParams";

const NATIVE_APP_BASE_URL = "https://asos-medialabs.onelink.me/PXyX";

const appPreferencesDefaults = {
  openInApp: OPEN_IN_APP_DEFAULT_ACTION,
  expireOpenInApp: 0,
  appIsPrimaryChannel: false,
  dismissedOpenApp: 0,
  dismissedOn: 0
};

const MEN_PATH_REGEX = [
    /\/men(\/)?$/,
    /\/uomo(\/)?$/,
    /\/mezczyzni(\/)?$/,
    /\/maend(\/)?$/,
    /\/hombre(\/)?$/,
    /\/herren(\/)?$/,
    /\/homme(\/)?$/,
    /\/man(\/)?$/,
    /\/heren(\/)?$/
  ],
  WOMEN_PATH_REGEX = [
    /\/women(\/)?$/,
    /\/donna(\/)?$/,
    /\/kobiety(\/)?$/,
    /\/kvinder(\/)?$/,
    /\/mujer(\/)?$/,
    /\/damen(\/)?$/,
    /\/femme(\/)?$/,
    /\/kvinna(\/)?$/,
    /\/dames(\/)?$/
  ];

/* istanbul ignore next */
export const isWindowHidden = () => {
  return document.visibilityState == "hidden";
};

export const TIMEOUT_OPEN_IN_APP = 3000;

export const get = key => {
  /* istanbul ignore next */
  if (!isWindowDefined()) {
    return;
  }
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      return null;
    }
  }
};

export const set = (key, value) => {
  const stringifiedValue =
    typeof value === "object" ? JSON.stringify(value) : value;
  try {
    localStorage?.setItem(key, stringifiedValue);
  } catch (error) {
    if (!(error instanceof DOMException && error.name === "SecurityError")) {
      throw error;
    }
  }
};

const getTimeToRememberOpenInAppAction = timeToMilliseconds(0, 72, 0, 0); // 72 hours
const getTimeToRememberUserDiscardedDialog = timeToMilliseconds(5, 0, 0, 0); // 5 days
const getTimeToRememberAdParams = timeToMilliseconds(30, 0, 0, 0); // 30 days

export const getDefaultPreferences = () => {
  return Object.assign({}, appPreferencesDefaults);
};

export const getDiscardPreferences = () => {
  const dismissedOn = Date.now();
  return Object.assign({}, appPreferencesDefaults, {
    dismissedOn: dismissedOn
  });
};

export const getOpenAppPreferences = () => {
  return Object.assign({}, appPreferencesDefaults, {
    openInApp: OPEN_IN_APP_USER_ACTION,
    expireOpenInApp: Date.now() + TIMEOUT_OPEN_IN_APP
  });
};

export const getOpenAppSuccessPreferences = () => {
  return {
    appIsPrimaryChannel: true,
    dismissedOpenApp: Date.now(),
    openInApp: OPEN_IN_APP_DEFAULT_ACTION,
    expireOpenInApp: 0
  };
};

const getIsDeepLinkExpired = (dismissedOpenApp, now) => {
  return dismissedOpenApp + getTimeToRememberOpenInAppAction < now;
};

const getIsDismissedOnExpired = (dismissedOn, now) => {
  return dismissedOn + getTimeToRememberUserDiscardedDialog < now;
};

export const initialise = (showBanner, preferences, url, pathAcceptsBanner) => {
  if (!pathAcceptsBanner) {
    return;
  }

  const now = Date.now(),
    {
      dismissedOn = 0,
      appIsPrimaryChannel,
      dismissedOpenApp = 0
    } = preferences;

  if (appIsPrimaryChannel) {
    // when the user has used the app once
    // deeplink is a link to a native app
    const isDeepLinkExpired = getIsDeepLinkExpired(dismissedOpenApp, now);
    if (isDeepLinkExpired) {
      showBanner();
    } else {
      if (isIos()) {
        showBanner();
      }
      if (isAndroid()) {
        window.location.assign(url);
      }
    }
  } else {
    // when the user has NOT used the app before
    const isDismissedOnExpired = getIsDismissedOnExpired(dismissedOn, now);
    if (isDismissedOnExpired) {
      removeValueFromLocalStorage(NATIVE_BANNER_STORAGE_KEY);
      showBanner(getDefaultPreferences());
    }
  }
};

export const detectPlatform = () => {
  if (isIos()) {
    return "ios";
  }
  if (isAndroid()) {
    return "android";
  }
  return null;
};

export const doesPathAcceptsBanner = storeCode => {
  let path = window.location.pathname.toLowerCase();
  // remove trailing slash
  path = path.replace(/\/$/, "");

  const parsedPath = path.split("/").join(""),
    isMenPage = matchPathInUrl(path, MEN_PATH_REGEX),
    isWomenPage = matchPathInUrl(path, WOMEN_PATH_REGEX),
    isBasePath = parsedPath === storeCode.toLowerCase() || parsedPath === "",
    isPDP = !!window.asos.pdp;

  return isMenPage || isWomenPage || isBasePath || isPDP;
};

export const matchPathInUrl = (url, localisedPaths) => {
  return localisedPaths.reduce((acc, el) => {
    return acc ? true : url.search(el) > -1;
  }, false);
};

export const getCorrespondingAppUrl = (isSupportedPlatform, customerId) => {
  if (!isSupportedPlatform) {
    return null;
  }

  const adParams = get(NATIVE_BANNER_AD_PARAMS_STORAGE_KEY)?.value || {};
  const currentUrl = new URL(window.location.href);
  const currentParams = currentUrl.searchParams;
  const ctaRef =
    currentParams.get("ctaref") ||
    currentParams.get("ctaRef") ||
    currentParams.get("CTARef");

  currentUrl.search = "";

  const params = {
    ...adParams,
    deep_link_value: currentUrl.toString(),
    channelref: "smartbanner",
    custref: customerId ?? "-1",
    ...(ctaRef && { ctaref: ctaRef })
  };

  const url = new URL(NATIVE_APP_BASE_URL);
  url.search = new URLSearchParams(params).toString();

  return url;
};

const allowedAdParams = [
  "pid",
  "af_sub1",
  "c",
  "clickid",
  "is_retargeting",
  "af_click_lookback",
  "af_reengagement_window",
  "af_inactivity_window",
  "af_adset_id",
  "af_sub_siteID",
  "ranMID",
  "ranEAID",
  "ranSiteID",
  "utm_source",
  "utm_medium",
  "utm_content",
  "utm_campaign",
  "link",
  "promo",
  "source",
  "affid",
  "channelref",
  "pubref",
  "MID",
  "publisher",
  "siteID",
  "af_dp",
  "irgwc",
  "uid",
  "transaction_id",
  "awc",
  "siteId",
  "datetime",
  "gclid",
  "ppcadref",
  "btn_ref",
  "_cclid"
];

export const saveAdParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const isRedirectedFromAd = searchParams.has("pid");

  if (!isRedirectedFromAd) {
    return;
  }

  const adParams = {};

  allowedAdParams.forEach(param => {
    if (searchParams.has(param)) {
      adParams[param] = searchParams.get(param);
    }
  });

  set(NATIVE_BANNER_AD_PARAMS_STORAGE_KEY, {
    value: adParams,
    expiry: Date.now() + getTimeToRememberAdParams
  });
};

export const expireAdParams = () => {
  const { value = {}, expiry } = get(NATIVE_BANNER_AD_PARAMS_STORAGE_KEY) || {};

  if (Object.keys(value).length === 0) {
    return;
  }

  if (expiry > Date.now()) {
    return;
  }

  set(NATIVE_BANNER_AD_PARAMS_STORAGE_KEY, { value: {}, expiry: 0 });
};

export const validate = ({ isSupportedPlatform, url, welcomeMessage = {} }) => {
  if (!isSupportedPlatform) {
    return;
  }

  if (url == null) {
    return;
  }

  if (Object.keys(welcomeMessage).length === 0) {
    return;
  }

  if (
    welcomeMessage.display !== false &&
    welcomeMessage.browsingLocalStore !== true
  ) {
    return;
  }

  return true;
};
