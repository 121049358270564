import {
  asosGlobalNamespace,
  sdkGlobalNamespace,
  customerGlobalNamespace,
  savedItemsSdkGlobalNamespace
} from "../../template/constants";

export const initialise = context => {
  const { getAccessToken } =
    window[asosGlobalNamespace][sdkGlobalNamespace].identity;
  const interactionCallback = () => {
    if (window.newrelic) {
      window.newrelic.interaction().save();
    }
  };

  return window[asosGlobalNamespace][customerGlobalNamespace][
    savedItemsSdkGlobalNamespace
  ].sdk({ context, configuration: { getAccessToken, interactionCallback } });
};
