import { getConfiguration } from "./bagConfiguration";
import clientConfig from "../../template/clientConfig";
import { eventBus } from "../../helpers/eventing/events";
import log from "../../helpers/logger";
import promiseWithTimeout from "../../helpers/promiseWithTimeout";
import {
  asosGlobalNamespace,
  sdkGlobalNamespace
} from "../../template/constants";
import { noticeError } from "@src/helpers/monitoring";

export const initialise = context =>
  new Promise(resolve =>
    requirejs(["bagSdk/sdk"], sdk =>
      resolve(sdk.getInstance(context, getConfiguration()))
    )
  );

export const waitForBagSDK = () =>
  new Promise((resolve, reject) => {
    if (clientConfig.isServer()) {
      return reject();
    }

    const {
      /* istanbul ignore next */ [asosGlobalNamespace]:
        /* istanbul ignore next */ {
          [sdkGlobalNamespace]: /* istanbul ignore next */ {
            bag
          } = /* istanbul ignore next */ {}
        } = /* istanbul ignore next */ {}
    } = window;
    if (bag) {
      resolve(bag);
    } else {
      eventBus.addListener("bag-sdk-ready", resolve);
    }
  });

export const getBagSummary = () =>
  waitForBagSDK()
    .then(bag => bag.getBagSummary())
    .catch(error => noticeError(error));

export const removeItem = (itemId, itemType) =>
  waitForBagSDK()
    .then(bag => promiseWithTimeout(bag.removeItem(itemId, itemType)))
    .catch(error => noticeError(error));

export const getBag = () =>
  waitForBagSDK()
    .then(bag => bag.getBag())
    .catch(error => noticeError(error));

export const subscribeToUpdates = callback =>
  waitForBagSDK()
    .then(bag => {
      $(bag).on(
        "bagUpdated:completed",
        (
          _event,
          _eventType,
          { bag, messages },
          { sellerChanged, containsHotProduct } = {}
        ) => {
          callback({ bag, messages, sellerChanged, containsHotProduct });
        }
      );
    })
    .catch(/* istanbul ignore next */ log.error);

export const subscribeToError = callback =>
  waitForBagSDK().then(bag => {
    $(bag).on(
      "addToBag:error",
      (_event, _error, { containsHotProduct, initiator } = {}) => {
        callback({
          containsHotProduct,
          initiator
        });
      }
    );
  });

export const startCheckout = () =>
  waitForBagSDK()
    .then(bag => bag.startCheckout())
    .catch(error => noticeError(error));
