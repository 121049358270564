import request from "./request";
import promiseWithTimeout from "../helpers/promiseWithTimeout";
import { getContext } from "../client/context";
import logger from "../helpers/logger";
import {
  getSuggestionsUrl,
  getCountryUrl,
  getPaymentProviderUrl,
  getWelcomeMessageUrl,
  getContentApiUrl,
  getCustomerProfileUrl,
  getDeliveryOptionsUrl
} from "./apiUrls";
import sdkInstanceProvider from "@src/client/sdk/sdkInstanceProvider.js";
import { ASOS_HEADERS_PLAT, ASOS_HEADERS_NAME } from "./constants";
import env from "../env";

export { default as fetchClientNavigation } from "./fetchClientNavigation";
export { default as fetchNavigation } from "./fetchNavigation";

export const fetchSuggestions = (storeCode, lang, countryCode, query) => {
  const url = getSuggestionsUrl(storeCode, lang, countryCode, query);

  logger.info(`(${storeCode}) - Fetching navigation from ${url}`);
  return request(url);
};

export const fetchCountry = (country, lang, platform) => {
  const url = getCountryUrl(country, lang, platform);
  logger.info(`Fetching country data for ${country} from ${url}`);
  return request(url);
};

export const fetchPaymentProviders = keyStoreDataversion => {
  const url = getPaymentProviderUrl({ keyStoreDataversion });
  logger.info(`Fetching payment providers from ${url}`);
  return request(url);
};

export const fetchDefaultDeliveryCountry = async customerId => {
  const url = getCustomerProfileUrl({
    customerId,
    params: {
      fields: "deliveryCountry"
    }
  });

  const { getAccessToken } = await sdkInstanceProvider("identity");
  const token = await getAccessToken();

  const headers = {
    "asos-c-name": "asos-web-site-chrome",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`
  };

  return request(url, { headers });
};

/**
 * @returns asos-web-request response object
 */
export const fetchSocialTags = async customerId => {
  const url = getCustomerProfileUrl({
    customerId,
    endpoint: "/socialtags"
  });

  const { getAccessToken } = await sdkInstanceProvider("identity");
  const token = await getAccessToken();

  const headers = {
    "asos-c-name": "asos-web-site-chrome",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`
  };

  return request(url, { headers, timeout: 5000 }, true);
};

export const getDeliveryOptions = (defaultDeliveryCountry, store) => {
  const deliveryOptionsUrl = getDeliveryOptionsUrl(defaultDeliveryCountry, {
    store
  });

  const headers = {
    "asos-c-name": "asos-web-site-chrome",
    "Content-Type": "application/json"
  };

  logger.info(
    `Fetching delivery options with ${defaultDeliveryCountry} and ${store} from ${deliveryOptionsUrl}`
  );

  return request(deliveryOptionsUrl, { headers });
};

export const fetchWelcomeMessage = (
  geoCountry,
  browseCountry,
  platform,
  deliveryCountry
) => {
  const url = getWelcomeMessageUrl(
    geoCountry,
    browseCountry,
    platform,
    deliveryCountry
  );

  const headers = {
    "asos-c-name": "asos-web-site-chrome",
    "Content-Type": "application/json"
  };

  logger.info(
    `Fetching welcome message for geoCountry "${geoCountry}", browseCountry "${browseCountry}", and deliveryCountry "${deliveryCountry}" from ${url}`
  );

  return request(url, { headers });
};

export const fetchTargetedContent = parameters => {
  const url = getContentApiUrl(parameters);
  const clientContext = getContext();
  const buildVersion = clientContext && clientContext.getBuildVersion();
  const headers = {
    "asos-c-name": ASOS_HEADERS_NAME,
    "asos-c-plat": ASOS_HEADERS_PLAT,
    "asos-c-ver": buildVersion
  };

  if (!url) {
    logger.error(`Content Targeting URL is not defined`);
    return Promise.reject("Content Targeting URL is not defined");
  }

  logger.info(`Fetching targeted content from ${url}`);
  return promiseWithTimeout(
    request(url, { headers }),
    8000,
    "Content Api is not responding"
  );
};

export const fetchSitecoreBearerToken = () => {
  const {
    SITECORE_LOGIN_URL: url,
    SITECORE_LOGIN_USER: username,
    SITECORE_LOGIN_PASS: password,
    SITECORE_LOGIN_DOMAIN: domain
  } = process.env;
  const body = {
    username,
    password,
    domain
  };
  const method = "POST";
  const headers = {
    "Content-Type": "application/json"
  };
  return request(url, { method, headers, body });
};

export const fetchTemplateFromBlobStorage = (
  siteChromeVersion,
  platform,
  countryCode
) => {
  const url = env("REDIS_WILDCARD").replace(
    "*",
    `${siteChromeVersion}-platform=${platform}&country=${countryCode}`
  );

  return request(url);
};

export const sendEventToAlfred = (event, url) => {
  const method = "POST";
  const headers = {
    "Content-Type": "application/json"
  };
  return request(url, { method, headers, body: event }).catch(e => {
    console.error("Alfred event failed", e);
  });
};
