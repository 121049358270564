import { createSelector } from "reselect";
import { SUCCESS, FAILED } from "./constants";

const getUser = state => state.user;

export const isLoggedIn = createSelector(getUser, user => user.isLoggedIn);

export const getFirstName = createSelector(
  getUser,
  user => user.profile.firstName
);

export const getIsRecognised = createSelector(
  getUser,
  user => user.profile.isRecognised
);

export const getCustomerId = createSelector(
  getUser,
  user => user.profile.customerId
);

export const getIdentityHasResponded = createSelector(getUser, user =>
  [SUCCESS, FAILED].includes(user.fetchingStatus)
);
