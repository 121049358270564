import { useEffect, useState, useRef } from "react";
import { MODAL_TYPES, MODAL_ANIMATION_TIME_MS } from "../constants";

export const useRenderManagement = ({
  isOpen,
  modalType,
  forceDOMRewrite,
  onClosedDelegate
}) => {
  const [shouldRenderModal, setShouldRenderModal] = useState(false);
  const [shouldRenderPageOverlay, setShouldRenderPageOverlay] = useState(false);
  const derenderTimeout = useRef(null);

  const derenderNow = () => {
    setShouldRenderModal(!forceDOMRewrite);
    setShouldRenderPageOverlay(false);
    onClosedDelegate();
  };

  const derenderWithAnimationDelay = () => {
    clearTimeout(derenderTimeout.current);
    derenderTimeout.current = setTimeout(() => {
      derenderNow();
    }, MODAL_ANIMATION_TIME_MS);
  };

  useEffect(() => {
    if (isOpen) {
      clearTimeout(derenderTimeout.current);
    }

    if (isOpen !== shouldRenderModal) {
      if (isOpen) {
        setShouldRenderPageOverlay(true);
        setShouldRenderModal(true);
      } else {
        modalType === MODAL_TYPES.SIDE_RIGHT
          ? derenderWithAnimationDelay()
          : derenderNow();
      }
    }
  }, [isOpen, modalType, forceDOMRewrite]);

  return { shouldRenderModal, shouldRenderPageOverlay };
};
