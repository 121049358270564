import { getContext } from "@src/client/context";
import getRsftWelcomeMessageAlreadyClosed from "./getRsftWelcomeMessageAlreadyClosed";
import { getDeliveryOptions } from "@src/api/index";
import { getResolvedDeliveryCountry } from "@src/template/state/modules/welcomeMessage/getResolvedDeliveryCountry";
import { getOrSetInLocalStorageWithExpiryAsync } from "@src/helpers/webStorage";
import {
  RSFT_DELIVERY_OPTIONS_CACHE_TTL_MS,
  RSFT_DELIVERY_OPTIONS_LOCAL_STORAGE_PREFIX
} from "@template/constants";

const deliveryOptionsGetter = async (resolvedDeliveryCountry, storeId) => {
  const value = await getDeliveryOptions(resolvedDeliveryCountry, storeId);
  const timeToLiveMs = RSFT_DELIVERY_OPTIONS_CACHE_TTL_MS;

  return { value, timeToLiveMs };
};

const getUserRsftWelcomeMessageMetadata = async minorCountries => {
  const context = getContext();
  const currentBrowseCountry = context.getBrowseCountry();
  const geoCountry = context.getGeoCountry();
  const storeId = context.getStoreId();

  const rsftMessageDismissedThisSession = getRsftWelcomeMessageAlreadyClosed();

  if (geoCountry === currentBrowseCountry || rsftMessageDismissedThisSession) {
    return { shouldRequest: false };
  }

  const deliveryCountry = minorCountries[geoCountry] || geoCountry;

  let resolvedDeliveryCountry;
  try {
    resolvedDeliveryCountry = await getResolvedDeliveryCountry({
      geoCountry: deliveryCountry,
      minorCountries
    });
  } catch (_error) {
    return { shouldRequest: false };
  }

  if (!resolvedDeliveryCountry) {
    return { shouldRequest: false };
  }

  let deliveryOptions;
  try {
    deliveryOptions = await getOrSetInLocalStorageWithExpiryAsync(
      {
        cacheKeyPrefix: RSFT_DELIVERY_OPTIONS_LOCAL_STORAGE_PREFIX,
        returnCachedOnDelegateFailure: false,
        removeOtherCacheItems: false,
        localStorageValueDelegate: deliveryOptionsGetter
      },
      resolvedDeliveryCountry,
      storeId
    );
  } catch (_error) {
    return { shouldRequest: false };
  }

  const deliveryOptionsAvailable =
    !!deliveryOptions.countries[0].deliveryMethods.length;

  return {
    shouldRequest: !deliveryOptionsAvailable,
    resolvedDeliveryCountry
  };
};

export default getUserRsftWelcomeMessageMetadata;
