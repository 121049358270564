import sdkInstanceProvider from "@src/client/sdk/sdkInstanceProvider.js";
import { asosGlobalNamespace } from "@template/constants";
import clientConfig from "@template/clientConfig";
import getSocialTags from "./getSocialTags";

export const setupPageDataEvent = async () => {
  if (!window[asosGlobalNamespace].webContext) {
    return;
  }

  const {
    affiliateId,
    browseCountry,
    browseCurrency,
    floor,
    platform,
    geoCountry
  } = window[asosGlobalNamespace].webContext.getWebContext();

  const { href, pathname, hostname, search: queryString } = window.location;
  const { title: pageTitle, referrer } = document;

  const {
    customer: { profile }
  } = await sdkInstanceProvider("identity");

  let isAuthenticated,
    customerId,
    firstTimeBuyer,
    isAnonymous = true;

  try {
    ({ isAuthenticated, isAnonymous, customerId, firstTimeBuyer } =
      await profile());
  } catch (e) {} // eslint-disable-line no-empty

  const socialTags = await getSocialTags({ isAnonymous, customerId });

  const { ENVIRONMENT_MODE: env } = clientConfig.get();

  const pageData = {
    href,
    pathname,
    pageTitle,
    hostname,
    queryString,
    browseCurrency,
    browseCountry,
    floor,
    platform,
    referrer,
    env,
    affiliateId,
    ...(typeof isAuthenticated !== "undefined" && { isAuthenticated }),
    ...(typeof customerId !== "undefined" && { customerId }),
    ...(typeof firstTimeBuyer !== "undefined" && { firstTimeBuyer }),
    geoCountry,
    socialTags
  };

  window[asosGlobalNamespace].eventBus.emit(
    "tracking.pageData.ready",
    pageData
  );
};
