class Storage {
  constructor() {
    this.storageKey = "Asos.Content.Targeting";
    this.storageType = "sessionStorage";
    this.storageTimeout = 300000;
  }

  isAvailable() {
    try {
      const storage = window[this.storageType];
      const x = "__storage_test__";

      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  }

  isValid(storedData) {
    if (!storedData) {
      return false;
    }
    if (new Date().getTime() - storedData.timestamp >= this.storageTimeout) {
      return false;
    }

    return true;
  }

  parseData(storageData) {
    try {
      return JSON.parse(storageData);
    } catch (e) {
      return null;
    }
  }

  getData(parameters) {
    if (!this.isAvailable()) {
      return null;
    }

    const key = this.getKey(parameters);
    const storageData = window[this.storageType].getItem(key);
    const data = this.parseData(storageData);

    if (!this.isValid(data)) {
      return null;
    }

    return data.content;
  }

  setData(data, parameters) {
    if (this.isAvailable()) {
      const value = {
        content: data,
        timestamp: new Date().getTime()
      };

      const key = this.getKey(parameters);
      const storageValue = JSON.stringify(value);
      window[this.storageType].setItem(key, storageValue);
    }
  }

  getKey(parameters) {
    const keyName = JSON.stringify(parameters);
    const encryptedKey = btoa(keyName);
    return this.storageKey + "-" + encryptedKey;
  }
}

export default Storage;
