import PropTypes from "prop-types";
import { withTranslation } from "@template/components/translation";

import styles from "./index.css";

const showDeliveryPropositionDescription = lang => {
  const languagesWithoutDescription = ["nl-NL", "sv-SE"];
  return !languagesWithoutDescription.includes(lang);
};

const DeliveryProposition = ({
  defaultStoreUrl,
  formatTranslation,
  lang,
  countryCode
}) => {
  // TEMP: Hide delivery proposition for French language until the correct strings are available
  if (countryCode === "FR") return null;

  return (
    <div className={styles.deliveryProposition}>
      <span className={styles.title}>
        {formatTranslation("minibag_footer_delivery_proposition_title")}
      </span>
      <span className={styles.description}>
        {showDeliveryPropositionDescription(lang) && (
          <span>
            {formatTranslation(
              "minibag_footer_delivery_proposition_description"
            )}
          </span>
        )}
        <a
          className={styles.descriptionLink}
          href={defaultStoreUrl.concat(
            formatTranslation(
              "minibag_footer_delivery_proposition_delivery_information_link"
            )
          )}
        >
          {formatTranslation("minibag_footer_delivery_proposition_link_text")}
        </a>
      </span>
    </div>
  );
};

DeliveryProposition.propTypes = {
  defaultStoreUrl: PropTypes.string.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired
};

export default withTranslation(DeliveryProposition);
