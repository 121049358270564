import { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import {
  get,
  set,
  initialise,
  doesPathAcceptsBanner,
  getCorrespondingAppUrl,
  detectPlatform,
  validate,
  isWindowHidden,
  saveAdParams,
  expireAdParams,
  getDefaultPreferences,
  getOpenAppPreferences,
  getDiscardPreferences,
  getOpenAppSuccessPreferences,
  TIMEOUT_OPEN_IN_APP,
  OPEN_IN_APP_DEFAULT_ACTION,
  OPEN_IN_APP_USER_ACTION,
  OPEN_IN_APP_UNEXPECTED_USER_ACTION,
  NATIVE_BANNER_STORAGE_KEY
} from "@src/template/helpers/nativeAppBanner";
import { isAndroid, isIos } from "@src/helpers/deviceDetection";
import styles from "./index.css";
import deferredStyles from "./deferred.css";
import { withTranslation } from "@template/components/translation";
import { trackNativeAppBannerGetApp } from "@src/helpers/eventing/events";
import { isWindowDefined } from "@src/helpers/url";
import { addPageAction } from "@src/helpers/monitoring";
import sdkInstanceProvider from "@src/client/sdk/sdkInstanceProvider";

let timeout;
const useHideBannerCallback = isBannerDisplayed => {
  const callbackRef = useRef(null);
  const setCallback = cb => {
    callbackRef.current = cb;
  };

  useEffect(() => {
    if (!isBannerDisplayed && callbackRef.current) {
      callbackRef.current();
    }
    callbackRef.current = null;
  }, [isBannerDisplayed]);

  return setCallback;
};

const resetBannerOnTimeout = (expireOpenInApp, showBannerFn) => {
  return () => {
    const settings = getDefaultPreferences();
    const isHidden = isWindowHidden();
    if (isHidden && expireOpenInApp < Date.now()) {
      set(NATIVE_BANNER_STORAGE_KEY, settings);
      clearTimeout(timeout);
    } else {
      showBannerFn(settings);
      set(NATIVE_BANNER_STORAGE_KEY, settings);
    }
  };
};

const NativeAppBanner = ({ welcomeMessage, storeCode, formatTranslation }) => {
  const osPlatform = detectPlatform();
  const isSupportedPlatform = !!osPlatform;

  const [isBannerDisplayed, setIsBannerDisplayed] = useState(false);

  const [nativeBannerSettings, setNativeBannerSettings] = useState(
    getDefaultPreferences()
  );

  const browserVisibilityChangeAndroid = useCallback(() => {
    if (!isSupportedPlatform || !isWindowHidden()) {
      return;
    }
    const preferences = get(NATIVE_BANNER_STORAGE_KEY);
    if (isWindowHidden() && preferences?.expireOpenInApp >= Date.now()) {
      clearTimeout(timeout);
      const prefUpdate = getOpenAppSuccessPreferences();

      set(NATIVE_BANNER_STORAGE_KEY, prefUpdate);
      setNativeBannerSettings(prefUpdate);
    }
  }, [isSupportedPlatform]);

  const showBanner = useCallback(
    withSettings => {
      if (!isBannerDisplayed) {
        addPageAction("nativeAppBannerShow");
      }
      setIsBannerDisplayed(true);
      if (withSettings !== null) {
        setNativeBannerSettings(withSettings);
      }
    },
    [isBannerDisplayed]
  );

  const handleMobileAppUrlsAndroid = useCallback(() => {
    const preferences = get(NATIVE_BANNER_STORAGE_KEY);

    if (
      preferences === null ||
      preferences?.openInApp === OPEN_IN_APP_DEFAULT_ACTION
    ) {
      return;
    }

    const { expireOpenInApp, openInApp } = preferences;

    if (
      expireOpenInApp >= Date.now() &&
      openInApp === OPEN_IN_APP_UNEXPECTED_USER_ACTION
    ) {
      clearTimeout(timeout);
      set(NATIVE_BANNER_STORAGE_KEY, getDefaultPreferences());
    }

    if (openInApp === OPEN_IN_APP_USER_ACTION) {
      const resetAction = resetBannerOnTimeout(expireOpenInApp, showBanner);

      timeout = setTimeout(resetAction, TIMEOUT_OPEN_IN_APP);
      set(
        NATIVE_BANNER_STORAGE_KEY,
        Object.assign({}, preferences, {
          openInApp: OPEN_IN_APP_UNEXPECTED_USER_ACTION
        })
      );
    }
  }, [showBanner]);

  useEffect(() => {
    const preferences = get(NATIVE_BANNER_STORAGE_KEY);
    if (preferences !== null) {
      setNativeBannerSettings(preferences);
    }
    if (isWindowDefined() && isAndroid()) {
      window.addEventListener("beforeunload", handleMobileAppUrlsAndroid);
      window.addEventListener(
        "visibilitychange",
        browserVisibilityChangeAndroid
      );
      return () => {
        window.removeEventListener("beforeunload", handleMobileAppUrlsAndroid);
        window.removeEventListener(
          "visibilitychange",
          browserVisibilityChangeAndroid
        );
      };
    }
  }, [browserVisibilityChangeAndroid, handleMobileAppUrlsAndroid]);

  useEffect(() => {
    expireAdParams();
    saveAdParams();
  }, []);

  const [customerId, setCustomerId] = useState(null);

  useEffect(() => {
    const getCustomerIdFromIdentitySdk = async () => {
      try {
        const identity = await sdkInstanceProvider("identity");
        const profile = await identity.customer.profile();
        setCustomerId(profile.customerId);
      } catch (e) {} // eslint-disable-line no-empty
    };

    getCustomerIdFromIdentitySdk();
  }, []);

  useEffect(() => {
    const url = getCorrespondingAppUrl(isSupportedPlatform, customerId);

    if (validate({ isSupportedPlatform, url, welcomeMessage })) {
      initialise(
        showBanner,
        nativeBannerSettings,
        url,
        doesPathAcceptsBanner(storeCode)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcomeMessage, storeCode]);

  const discardBanner = () => {
    const discardPreferences = getDiscardPreferences();
    hideBanner(discardPreferences, () => {
      set(NATIVE_BANNER_STORAGE_KEY, discardPreferences);
    });
  };

  const openInAppCallback = () => {
    const openAppPreferences = getOpenAppPreferences();
    hideBanner(openAppPreferences, () => {
      const url = getCorrespondingAppUrl(isSupportedPlatform, customerId);
      if (isIos()) {
        set(NATIVE_BANNER_STORAGE_KEY, getOpenAppSuccessPreferences());
      } else {
        set(NATIVE_BANNER_STORAGE_KEY, openAppPreferences);
      }
      window.location.assign(url);
    });
  };

  const handleGetApp = () => {
    trackNativeAppBannerGetApp(osPlatform);
    window.location.assign(
      getCorrespondingAppUrl(isSupportedPlatform, customerId)
    );
  };

  const hideBannerCallback = useHideBannerCallback(isBannerDisplayed);

  const hideBanner = (withSettings, fn) => {
    setIsBannerDisplayed(false);
    setNativeBannerSettings(withSettings);
    hideBannerCallback(fn);
  };

  const appStoreName = isIos() ? "App" : "Play";

  return (
    <div
      className={cx(styles.defaultVisibilityBanner, styles.asosAppSmartBanner)}
      style={isBannerDisplayed ? { display: "block" } : { display: "none" }}
    >
      <div className={styles.sbMessageView}>
        <div className={styles.sbMessageActions}>
          <div className={cx(styles.sbShowRatings, styles.sbMessage)}>
            <div className={styles.sbAppIcon}></div>
            <div className={cx(styles.asosName, deferredStyles.asosNameIcon)}>
              {formatTranslation("nativeappbanner_the_asos_app_text")}
            </div>
            <div>
              {formatTranslation("nativeappbanner_the_asos_appstore_text", {
                store_market: appStoreName
              })}
            </div>
          </div>
          <div className={styles.sbActions}>
            <button
              data-testid="native-app-banner-open"
              onClick={openInAppCallback}
              className={cx(styles.sbOpenInApp, styles.sbBtns)}
            >
              {formatTranslation("nativeappbanner_open_in_the_app_text")}
            </button>
            <button
              onClick={handleGetApp}
              className={cx(styles.sbInstall, styles.sbBtns)}
            >
              {formatTranslation("nativeappbanner_install_text")}
            </button>
          </div>
        </div>
      </div>
      <button
        className={cx(styles.sbClose, deferredStyles.sbCloseIcon)}
        aria-label={formatTranslation("icon_close")}
        onClick={discardBanner}
      >
        X
      </button>
    </div>
  );
};

NativeAppBanner.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  storeCode: PropTypes.string.isRequired,
  welcomeMessage: PropTypes.shape({
    browsingLocalStore: PropTypes.bool
  })
};

export default withTranslation(NativeAppBanner);
