import clientConfig from "../../template/clientConfig";

const buildConfiguration = () => {
  if (clientConfig.isLocal()) {
    return {
      bagApiUrl: "/api"
    };
  }

  return {
    xSiteOrigin: clientConfig.getXSiteOrigin()
  };
};

export const getConfiguration = () => buildConfiguration(clientConfig.get());
