import { initialise as initialiseIdentity } from "./identity";
import { initialise as initialiseBag } from "./bag";
import { initialise as initialiseSavedItems } from "./savedItems";
import { initialise as initialiseSubscriptions } from "./subscriptions";
import { initialise as initialiseAudience } from "./audience";
import { initialise as initialiseFeatures, startFeatures } from "./features";
import {
  asosGlobalNamespace,
  sdkGlobalNamespace
} from "../../template/constants";

const getBagContext = ({
  storeId,
  browseCountry: country,
  geoCountry: originCountry,
  browseCurrency: currency,
  browseLanguage: language,
  browseSizeSchema: sizeSchema,
  keyStoreDataversion
}) => ({
  storeId,
  country,
  originCountry,
  currency,
  language,
  sizeSchema,
  keyStoreDataversion
});

const getSavedItemsContext = ({
  storeId: storeCode,
  browseCurrency: currency,
  browseLanguage: language,
  browseSizeSchema: sizeSchema,
  keyStoreDataversion,
  browseCountry: country
}) => ({
  storeCode,
  currency,
  language,
  sizeSchema,
  keyStoreDataversion,
  country
});

const ready = name => sdk => {
  const sdkNamespace = window[asosGlobalNamespace][sdkGlobalNamespace];
  sdkNamespace[name] = sdk;
  window[asosGlobalNamespace].eventBus.emit(`${name}-sdk-ready`, sdk);
  return sdk;
};

export const initialise = async (store, context) => {
  const webContext = context.getWebContext();
  const bagSdkContext = getBagContext(webContext);
  const savedItemsSdkContext = getSavedItemsContext(webContext);
  const sdkNamespace = window[asosGlobalNamespace][sdkGlobalNamespace];
  return Promise.all([
    initialiseFeatures(webContext)
      .then(ready("features"))
      .then(startFeatures)
      .then(() => {
        window[asosGlobalNamespace].eventBus.emit(
          `features-mvt-ready`,
          sdkNamespace.features
        );
      }),
    initialiseIdentity(store)
      .then(ready("identity"))
      .then(() => {
        ready("savedItems")(initialiseSavedItems(savedItemsSdkContext));
        ready("subscriptions")(initialiseSubscriptions());
        ready("audience")(initialiseAudience());
        return initialiseBag(bagSdkContext).then(ready("bag"));
      })
  ]).then(() =>
    window[asosGlobalNamespace].eventBus.emit("sdk-ready", sdkNamespace)
  );
};
