export const withParams = (url, params = {}) => {
  const parsedUrl =
    typeof window === "undefined"
      ? new URL(url)
      : new URL(url, window.location.origin);

  Object.keys(params).forEach(key => {
    parsedUrl.searchParams.set(key, params[key]);
  });

  return parsedUrl.toString();
};
