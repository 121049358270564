import {
  GET_SEGMENTS,
  SET_SEGMENTS_FETCHING_STATUS,
  FAILED,
  FETCHING,
  SUCCESS
} from "./constants";
import {
  asosGlobalNamespace,
  sdkGlobalNamespace,
  audiencesSdkGlobalNamespace
} from "../../../constants";

const sdkRequest = /* istanbul ignore next */ async dispatch => {
  try {
    dispatch(setAudiencesFetchingState(FETCHING));

    const segments = await window[asosGlobalNamespace][sdkGlobalNamespace][
      audiencesSdkGlobalNamespace
    ].getSegments();

    dispatch({
      type: GET_SEGMENTS,
      payload: {
        fetchingStatus: SUCCESS,
        segments
      }
    });
  } catch (err) {
    dispatch({
      type: GET_SEGMENTS,
      error: true,
      payload: {
        fetchingStatus: FAILED,
        segments: null
      }
    });
  }
};

export const getAudiencesFromSDK =
  /* istanbul ignore next */
  () => /* istanbul ignore next */ dispatch => {
    return new Promise(function (resolve) {
      if (
        window[asosGlobalNamespace] &&
        window[asosGlobalNamespace][sdkGlobalNamespace] &&
        window[asosGlobalNamespace][sdkGlobalNamespace][
          audiencesSdkGlobalNamespace
        ]
      )
        return resolve(sdkRequest(dispatch));

      window[asosGlobalNamespace].eventBus.addListener("sdk-ready", () =>
        resolve(sdkRequest(dispatch))
      );
    });
  };

export const setAudiencesFetchingState = status => ({
  type: SET_SEGMENTS_FETCHING_STATUS,
  payload: status
});
