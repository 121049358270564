import {
  GET_SUBSCRIPTIONS,
  SET_SUBSCRIPTIONS_FETCHING_STATUS,
  FAILED,
  FETCHING,
  SUCCESS
} from "./constants";
import {
  asosGlobalNamespace,
  sdkGlobalNamespace,
  subscriptionsSdkGlobalNamespace
} from "../../../constants";

const sdkRequest = /* istanbul ignore next */ async dispatch => {
  try {
    dispatch(setSubscriptionsFetchingState(FETCHING));

    const subscriptions = await window[asosGlobalNamespace][sdkGlobalNamespace][
      subscriptionsSdkGlobalNamespace
    ].get();

    dispatch({
      type: GET_SUBSCRIPTIONS,
      payload: {
        fetchingStatus: SUCCESS,
        subscriptions
      }
    });
  } catch (err) {
    dispatch({
      type: GET_SUBSCRIPTIONS,
      error: true,
      payload: {
        fetchingStatus: FAILED,
        subscriptions: null
      }
    });
  }
};

export const getSubscriptionsFromSDK =
  /* istanbul ignore next */
  () => /* istanbul ignore next */ dispatch => {
    return new Promise(function (resolve) {
      if (
        window[asosGlobalNamespace] &&
        window[asosGlobalNamespace][sdkGlobalNamespace] &&
        window[asosGlobalNamespace][sdkGlobalNamespace][
          subscriptionsSdkGlobalNamespace
        ]
      )
        return resolve(sdkRequest(dispatch));

      window[asosGlobalNamespace].eventBus.addListener("sdk-ready", () =>
        resolve(sdkRequest(dispatch))
      );
    });
  };

export const setSubscriptionsFetchingState = status => ({
  type: SET_SUBSCRIPTIONS_FETCHING_STATUS,
  payload: status
});
