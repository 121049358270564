import { sdk } from "@asosteam/asos-web-subscriptions-sdk";
import { noticeError } from "@src/helpers/monitoring";

import {
  asosGlobalNamespace,
  sdkGlobalNamespace
} from "../../template/constants";

export const initialise = () => {
  const { getAccessToken } =
    window[asosGlobalNamespace][sdkGlobalNamespace].identity;

  const logger = {
    error: noticeError
  };

  const config = { getAccessToken, logger };

  return sdk(config);
};
